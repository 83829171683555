<template>
    <div>

        <v-btn class="link-btn-class" color="primary" :disabled="checkingAppExistence"
               :loading="checkingAppExistence">
            <!--<template v-if="sessionAppLink == null">-->
            <!--<a href="" @click.prevent="joinApp" class="pa-2 px-4 white&#45;&#45;text" style="text-decoration: none">ورود به جلسه</a>-->
            <!--</template>-->
            <template>
                <a :href="sessionAppLink" id="simiaroombtn" target="_blank" class="pa-2 px-4 white--text">ورود به
                    جلسه</a>
            </template>
        </v-btn>

        <v-dialog v-model="showDownloadApp" max-width="500">
            <v-card align="center" class="d-flex align-center justify-center flex-column">
                <v-card-text class="mt-8">برای دانلود از لینک های زیر استفاده کنید</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="text-center mb-3">
                        <v-btn class="ma-2">
                            <a href="http://app.simiaroom.com/apps/win/PlatRoom Setup 1.1.2.exe"
                               style="text-decoration: none">
                                <v-icon class="px-2">fab fa-windows</v-icon>
                                <span style="text-decoration: none" class="mt-1">ویندوز</span>
                            </a>
                        </v-btn>
                        <v-btn class="ma-2"
                        >
                            <a href="http://app.simiaroom.com/apps/mac/PlatRoom-1.1.2.dmg"
                               style="text-decoration: none">
                                <v-icon class="px-2">fab fa-apple</v-icon>
                                <span class="mt-1">مک</span>
                            </a>

                        </v-btn>
                        <v-btn class="ma-2"
                        >
                            <a href="http://app.simiaroom.com/apps/android/Platroom.apk"
                               style="text-decoration: none">
                                <v-icon class="px-2">fab fa-android</v-icon>
                                <span style="text-decoration: none" class="mt-1">اندروید</span>
                            </a>

                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-card class="elevation-0 mt-12">
            <v-card-text class="text-center">
                در صورتی که برنامه ارتباط تصویری پلتروم را بر روی سیستم خود ندارید، با کلیک کردن بر روی
                <v-btn @click="showDownloadApp = true" class="primary elevation-0 mx-auto" small>
                    این دکمه
                </v-btn>
                ، نسخه مورد نظر
                خود را دانلود کنید.
                <div>در صورتی که از گوشی Apple استفاده می نمایید، با پشتیبانی تماس بگیرید</div>
            </v-card-text>

        </v-card>
    </div>
</template>

<script>
    /*eslint-disable*/
    import customProtocolCheck from "custom-protocol-check";
    import {isMobileSafari} from 'mobile-device-detect';


    export default {
        props: ['role', 'session'],
        data() {
            return {
                dialog: false,
                loading: false,
                sessionAppLink: null,
                checkingAppExistence: null,
                showDownloadApp: false
            }
        },
        created() {

            if (this.session.state != 'used' && this.session.state == 'date_fixed') {
                // this.joinApp();
            }

        },
        mounted() {
            if (!isMobileSafari) {
                this.handleMeetingBtn();
            } else {
                this.joinApp();
            }

        },
        methods: {
            joinApp() {
                this.loading = true;
                return new Promise((resolve, reject) => {
                    if (this.role == 'doctor') {
                        this.$store.dispatch('doctorJoinSession', this.$route.params.sessionId).then((resp) => {
                            this.sessionAppLink = resp.data.URL;
                            resolve();
                        })
                    } else {
                        this.$store.dispatch('clientJoinSession', this.$route.params.sessionId).then((resp) => {
                            this.sessionAppLink = resp.data.URL;
                            this.loading = false;
                            resolve();
                        })
                    }
                });
            },
            handleMeetingBtn() {
                let element = document.getElementById('simiaroombtn');
                let self = this;

                element.addEventListener('click', (event) => {
                    event.preventDefault();
                    if (this.sessionAppLink == null) {
                        this.joinApp().then(() => {
                            let href = element.getAttribute('href');
                            this.checkingAppExistence = true;
                            if (href != null) {

                                event.preventDefault();
                                event.preventDefault ? event.preventDefault() : (event.returnValue = false);

                                customProtocolCheck(
                                    element.getAttribute('href'),
                                    function () {
                                        console.log('false');
                                        self.checkingAppExistence = false;
                                        self.showDownloadApp = true;
                                    },
                                    function () {
                                        console.log('true');
                                        self.checkingAppExistence = false;
                                    }
                                );

                            } else {
                                this.checkingAppExistence = false;
                            }
                            return;
                        });
                    } else {
                        let href = element.getAttribute('href');
                        this.checkingAppExistence = true;
                        if (href != null) {

                            event.preventDefault();
                            event.preventDefault ? event.preventDefault() : (event.returnValue = false);


                            customProtocolCheck(
                                element.getAttribute('href'),
                                function () {
                                    console.log('false');
                                    self.checkingAppExistence = false;
                                    self.showDownloadApp = true;
                                },
                                function () {
                                    console.log('true');
                                    self.checkingAppExistence = false;
                                }
                            );

                        } else {
                            this.checkingAppExistence = false;
                        }
                        return;
                    }

                });
            },
        }
    }
</script>

<style>
    #simiaroombtn {
        text-decoration: none;
    }
</style>