<template>
    <v-container v-if="session != null">
        <v-card>
            <v-card-title>
                دوره مشاوره
            </v-card-title>
            <v-card-text>
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            موضوع : {{session.cycle.heading}}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div>شرح :</div>
                            {{session.cycle.problem_description}}
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
        </v-card>

        <v-alert
                dark
                color="primary"
                dense
                class="mt-5 py-0 elevation-1"
        >
            <!--<v-row align="center">-->
            <!--<template v-if="session.state == 'used'">-->
            <!--جلسه با <strong class="secondary&#45;&#45;text">{{-->
            <!--session.doctor.full_name}}</strong> آغاز می شود. لطفا پیش از شروع جلسه، از کیفیت سرعت اینترنت خود-->
            <!--برگزار شده است.-->
            <!--<v-col class="grow">-->
            <!--جلسه برگزار شده است.-->
            <!--</v-col>-->
            <!--</template>-->
            <!--<template v-else>-->
            <!--<v-col class="grow">-->
            <!--تا دقایقی دیگر جلسه ۴۵ دقیقه ای مشاوره شما با <strong class="secondary&#45;&#45;text">{{-->
            <!--session.doctor.full_name}}</strong> آغاز می شود. لطفا پیش از شروع جلسه، از کیفیت سرعت اینترنت-->
            <!--خود-->
            <!--اطمینان حاصل فرمایید.-->
            <!--</v-col>-->
            <!--</template>-->

            <!--</v-row>-->
            <v-row align="center">
                <template v-if="isPassed == 0">
                    <v-col class="grow">
                        {{$t('$vuetify.startSession.passed.zero[0]')}}
                      {{session.diff_time}}
                      {{$t('$vuetify.startSession.passed.zero[1]')}}
                      <strong class="secondary--text">
                      {{session.doctor.full_name}}</strong>
                      {{$t('$vuetify.startSession.passed.zero[2]')}}
                    </v-col>
                </template>
                <template v-if="isPassed == 1">
                    <v-col class="grow">
                      {{$t('$vuetify.startSession.passed.one[0]')}}
                      <strong class="secondary--text">
                        {{session.doctor.full_name}}</strong>
                      {{$t('$vuetify.startSession.passed.one[1]')}}
                    </v-col>
                </template>
                <template v-if="isPassed == 2">
                    <v-col class="grow">
                      {{$t('$vuetify.startSession.passed.two[0]')}}
                      <strong class="secondary--text">
                        {{session.doctor.full_name}}
                      </strong>
                      {{$t('$vuetify.startSession.passed.two[1]')}}
                    </v-col>
                </template>
            </v-row>
        </v-alert>

        <v-card align="center" class="py-12" v-if="session.state =='date_fixed'">
            <v-card-text>
                <v-avatar
                        class="profile"
                        color="grey"
                        size="164"
                >
                    <img :src="session.doctor.avatar"/>
                </v-avatar>
                <div class="pa-2 font-weight-bold">
                    {{session.doctor.full_name}}
                </div>
            </v-card-text>
            <!--<v-card-text>{{ session.consult }}</v-card-text>-->
            <!--<v-card-text style="color: red">زمان شروع جلسه: {{ session.countDownDate }}</v-card-text>-->
            <!--            <stop-watch class="my-3" initial-minutes="120" initial-seconds="60"></stop-watch>-->
            <!--<Countdown-->
            <!--:starttime="startDateTime"-->
            <!--:endtime="endDateTime"-->
            <!--trans='{-->
            <!--"day":"Day",-->
            <!--"hours":"Hours",-->
            <!--"minutes":"Minuts",-->
            <!--"seconds":"Seconds",-->
            <!--"expired":"زمان جلسه گذشته است.",-->
            <!--"running":"جلسه آغاز شده است.",-->
            <!--"upcoming":"زمان باقیمانده تا شروع جلسه",-->
            <!--"status": {-->
            <!--"expired":"Expired",-->
            <!--"running":"Running",-->
            <!--"upcoming":"Future"-->
            <!--}}'-->
            <!--&gt;</Countdown>-->
            <!--            <v-btn>تست فنی</v-btn>-->
            <!--<v-card-text class="my-3">برای شروع جلسه یکی از پلتفرم های زیر را انتخاب نمایید.</v-card-text>-->
            <div class="text-center">
                <!--                <v-btn class="ma-2" color="warning" dark @click="joinG2M" :loading="loadingG2M">-->
                <!--                    GO TO MEETING-->
                <!--                </v-btn>-->
                <!--                <v-btn class="ma-2" color="primary" @click="joinBBB" :loading="loadingBBB">-->
                <!--                    BBB-->
                <!--                </v-btn>-->

                <!--                <v-btn class="link-btn-class" :disabled="(sessionAppLink == null) || checkingAppExistence" :loading="checkingAppExistence">-->
                <!--                    <a v-show="!checkingAppExistence" :href="sessionAppLink" id="simiaroombtn">ورود به جلسه</a>-->
                <!--                </v-btn>-->

                <EnterSessionBtn v-if="session != null" :session="session" :role="'client'"></EnterSessionBtn>
            </div>
            <!--<Net :nonce="nonce"></Net>-->
        </v-card>
    </v-container>
</template>

<script>
    /*eslint-disable*/
    import moment from 'moment-jalaali';
    import Countdown from "../Countdown";
    import Net from "../Net";
    import EnterSessionBtn from "./EnterSessionBtn";

    export default {
        data() {
            return {
                isPassed: 0,
                session: null,
                startDateTime: null,
                endDateTime: null,
                loadingG2M: false,
                loadingBBB: false,
                nonce: null,
            }
        },
        components: {
            Countdown,
            EnterSessionBtn,
            Net
        },
        created() {
            this.getStartSession();
        },
        mounted() {
            // this.handleMeetingBtn();
        },
        computed: {
            profile() {
                return this.$store.getters.getUserInfo
            }
        },
        methods: {
            getStartSession() {
                this.$store.dispatch('getStartSessionForClient', this.$route.params.sessionId).then((data) => {

                    // console.log('start session: ', data);

                    this.session = data.data;

                    if (this.session.fixed_date_time_g != null) {
                        let sessionTime = moment(this.session.fixed_date_time_g, 'YYYY-MM-DD HH:mm');
                        let sessionOffset = moment(this.session.fixed_date_time_g, 'YYYY-MM-DD HH:mm');
                        sessionOffset.add(45, 'minutes');
                        let now = moment();
                        if (now.isBefore(sessionTime)) {
                            this.isPassed = 0;
                        } else if (now.isBetween(sessionTime, sessionOffset)) {
                            this.isPassed = 1;
                        } else if (now.isAfter(sessionOffset)) {
                            this.isPassed = 2;
                        }
                    }
                    // this.currentDateTime = moment().utc().format('YYYY/MM/DD HH:MM');
                    // this.startDateTime = moment(data.data.fixed_date_time, 'jYYYY/jMM/jDD HH:mm').format('YYYY/MM/DD HH:mm');
                    // this.endDateTime = moment(this.startDateTime).add(45, 'm').format('YYYY/MM/DD HH:mm');
                    this.nonce = data.data.nonce;
                })
            },
            joinG2M() {
                this.loadingG2M = true;
                this.$store.dispatch('joinG2M', this.$route.params.sessionId).then((data) => {
                    window.open(data.data, '_blank');
                    this.loadingG2M = false;
                })
            },
            joinBBB() {
                this.loadingBBB = true;
                this.$store.dispatch('joinBBB', this.$route.params.sessionId).then((data) => {
                    window.open(data.data, '_blank');
                    this.loadingBBB = false;
                })
            }
        }
    };
</script>