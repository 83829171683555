<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="classicModal" max-width="450" persistent>
                <v-card class="d-flex align-center justify-center flex-column" style="overflow-x: hidden">
                    <v-card-title>
                        <h4>در حال تست سرعت اینترنت شما</h4>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <iframe v-if="showIframe" width="560" height="315" :src="iframeSrc" frameborder="0" class="test-iframe d-none" ref="iframe"></iframe>
                    <div class="loading-image">
                        <div class="loader">
                            <div class="atom-1"></div>
                            <div class="atom-2"></div>
                            <div class="atom-3"></div>
                        </div>
                    </div>
                </v-card>
            </v-dialog>
        </v-row>
        <div v-if="canStart" class="mt-5">
            can start
        </div>
        <div v-else-if="canStart===false" class="mt-8">
            <v-alert type="error" max-width="500" class="mx-3">
                متاسفانه سرعت اینترنت شما برای شروع جلسه مناسب نمی باشد.
            </v-alert>
        </div>
    </div>

</template>

<script>
    export default {
        props: {
          nonce: String
        },
        data() {
            return {
                classicModal: true,
                gotNoResponse: true,
                iframeSrc: null,
                showIframe: false,
                canStart: null
            }
        },
        created() {
            this.iframeSrc = window.location.origin + '/network/index.html?query=' + this.nonce;
            this.showIframe = true;

            window.addEventListener('testIsFinished', (event) => {
                console.log(event)
                if (this.gotNoResponse) {
                    this.gotNoResponse = false;
                    this.processTheResults(event.detail);
                }
            });
        },
        methods: {
            processTheResults(data) {
                this.canStart = data == 'ok' ? true : false;
                this.classicModal = false;
            }
        }
    }
</script>

<style scoped>
    .loader {
        position: relative;
        width: 10em;
        height: 10em;
        margin: 3em auto;
        perspective: 1200px;
    }

    div[class^="atom"] {
        width: 10em;
        height: 10em;
        border-radius: 50%;
        border-width: 4px;
        position: absolute;
        box-sizing: border-box;
        transform-style: preserve-3d;
    }
    .atom-1 {
        left: 0; top: 0;
        border-bottom-style: solid;
        border-bottom-color: #86C23B;
        animation: spin1 1s linear infinite;
    }
    .atom-2 {
        right: 0; top: 0;
        border-right-style: solid;
        border-right-color: #43378C;
        animation: spin2 1.2s linear infinite;
    }
    .atom-3 {
        right: 0; bottom: 0;
        border-top-style: solid;
        border-top-color: #86C23B;
        animation: spin3 1.4s linear infinite;
    }

    @keyframes spin1 {
        0% {
            transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
        }
        100% {
            transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
        }
    }
    @keyframes spin2 {
        0% {
            transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
        }
        100% {
            transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
        }
    }
    @keyframes spin3 {
        0% {
            transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
        }
        100% {
            transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
        }
    }
</style>